@import "~bootstrap/scss/bootstrap";
@import "_variables";
@import "_common";
@import "_responsive";

.left-nav {
  background-color: #ededed;
  width: 280px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
}
.main-content {
  width: 100%;
  margin-left: 280px;
}
.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  display: none;
}
.profile {
  margin-bottom: 36px;
  padding-bottom: 15px;
  position: relative;
  overflow: hidden;
  a {
    z-index: 1;
  }
}
.profile::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 230%;
  left: -70%;
  top: -110%;
  background: $primary;
  transform: rotate(60deg);
}
.profile::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 230%;
  right: -70%;
  top: -110%;
  background: $primary;
  transform: rotate(120deg);
}
.profile-photo {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 3px solid $secondary;
}
.social-links {
  margin: 1.5rem 0 1.5rem 0;
  a {
    width: 40px;
    height: 40px;
    line-height: 42px;
    border-radius: 50%;
    border: 1px solid gray;
    transition: all 0.3s ease-in;
    display: inline-block;
    margin-right: 7px;
    color: white;
  }
  a:hover {
    background-color: $secondary;
    transition: all 0.3s ease-in;
  }
}
.about-links button {
  margin-right: 1rem;
}
.menu-list {
  margin: 0;
  padding: 0;
  li {
    margin-bottom: 30px;
  }
  li a {
    padding: 0px 20px 0px 35px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
  }
  li i {
    margin-right: 10px;
    font-size: 18px;
    color: $primary;
  }
  i,
  span {
    vertical-align: middle;
  }
}
.home-section {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #ffffff;
}
.home-section::before {
  position: absolute;
  content: "";
  width: calc(100% - 280px);
  height: 100vh;
  top: 0px;
  left: 280px;
  background: rgba(0, 0, 0, 0.7);
}

.home-content {
  text-align: center;
  font-weight: 200;
  .heading {
    font-size: 40px;
    line-height: 50px;
    font-weight: 200;
    margin-bottom: 2px;
    color: #fff;
  }
}
.section-title {
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 200;
}
.description {
  padding: 25px 30px 30px;
  @extend .box-shadow;
}
.profile-img-sq {
  @extend .box-shadow;
  overflow: hidden;
  display: block;
  position: relative;
  padding: 20px;

  img {
    width: 100%;
    text-align: center;
    border-radius: 5px;
  }
}
.profile-img-sq::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 300%;
  left: -70%;
  top: -100%;
  background: $primary;
  transform: rotate(45deg);
  z-index: -1;
}
.about-me__content {
  @extend .box-shadow;
  margin-top: 30px;
  padding: 25px 30px 15px;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-bottom: 5px;
      max-width: 50%;
      flex: 50%;
      color: #666;
      line-height: 2;
      span label {
        min-width: 100px;
        font-weight: 600;
      }
    }
  }
}
.box {
  @extend .box-shadow;
  text-align: center;
  margin-bottom: 1rem;
  padding: 2rem;
  width: 100%;
  .counter {
    font-size: 28px;
    font-weight: 600;
  }
}
.box .box_title {
  border-bottom: 2px solid #eee;
  color: #666;
  text-align: left;
  h4 {
    font-weight: 400;
    i {
      font-size: 16px;
      padding: 5px;
      text-align: center;
      border: 1px solid #666;
      border-radius: 50%;
      color: #666;
      height: 30px;
      width: 30px;
    }
    span {
      vertical-align: middle;
      font-size: 1.3rem;
    }
  }
}
.box .box_details {
  position: relative;
  text-align: left;
  padding: 15px 0px;
  p {
    line-height: 1.8rem;
  }
  p,
  h5 {
    padding-left: 15px;
    margin-bottom: 0 !important;
  }
  label {
    font-weight: 500;
  }
  .institute {
    color: $primary;
  }
}
.box_details::before {
  position: absolute;
  content: "";
  top: 19px;
  height: 15px;
  width: 5px;
  background-color: $primary;
}
.menu-toogle-icon {
  width: 45px;
  height: 45px;
  margin-right: 0px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  right: 18px;
  top: 20px;
  background: $primary;
  z-index: 5;
  display: none;
  box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.10);
  .menu-toogle-icon__open {
    width: 20px;
    margin: 14px auto;
    span {
      display: block;
      margin: 3px 0;
      background-color: #fff;
      height: 3px;
    }
  }

  .menu-toogle-icon__close {
    display: none;
    width: 20px;
    margin: 14px auto;
    span {
      display: block;
      margin: 3px 0;
      background-color: #fff;
      height: 3px;
    }
    span:nth-child(1) {
      transform: rotate(45deg);
      margin-top: 20px;
    }
    span:nth-child(2) {
      transform: rotate(-45deg);
      margin-top: -6px;
    }
  }
}
.resume-rating {
  text-align: left;
  margin: 0 !important;
  padding: 15px 0 !important;
  .title {
    text-align: center;
  }
  span:nth-child(1) {
    min-width: 150px;
    display: block;
  }
}
.portfolio-img-title p {
  position: relative;
  color: #fff;
  font-weight: 200;
  z-index: 2;
  text-align: center;
}
.portfolio-img::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  display: block;
  width: 100%;
  height: 25px;
}
.portfolio-img {
  margin-bottom: 1rem;
  a {
    z-index: 1;
    color: #fff;
    text-align: center;
    font-weight: 200;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
.blog-title {
  border-top: 1px solid #e9e8eb;
  text-align: left;
  margin-bottom: 0;
}
.blog-date {
  display: flex;
  justify-content: space-between;
  color: gray;
  font-size: 12px;
  padding: 5px;
}
.contact-form {
  padding: 15px;
}

.contact-form .form-group {
  margin-bottom: 15px;
  position: relative;
  input:focus, textarea:focus {
    border:1px solid $primary;
    box-shadow: none;
  }
}

.text-left {
    text-align: left !important;
}
.star-ratings{
    vertical-align: text-bottom;
}
.portfolio-box{
  padding: 10px;
  text-align: left !important;
  ul li{
    padding: 0;
    list-style-type: disclosure-closed;
    &::marker{
      color: $primary;

    }
  }
}
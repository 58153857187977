.btn-primary {
  background-color: $primary;
  border-color: $primary;
}
.text-primary {
  color: $secondary !important;
}
.text-secondary {
  color: $secondary !important;
}
.btn-primary:hover {
  background-color: $secondary;
  border-color: $secondary;
}
.box-shadow {
  border-radius: 10px;
  box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.10);
}

body {
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
  padding: 0;
  line-height: 1.5;
}
a {
  text-decoration: none;
  color: #333;
}
ul li {
  list-style: none;
}
.container-fluid {
  padding: 0;
}
.active {
  color: $primary !important;
}
.mr-10{
  margin-right: 10px !important;
}
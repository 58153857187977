.portfolioListBox{
    padding: 10px;
    margin: 10px;
}
.portfolioListBox strong{
    color: #7a7a7a;
}
.portfolioListBox h4{
    text-align: center;
    min-height: 58px;
}

.features li {
    list-style-type: circle;
}
.portfolioListBox__header{
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-bottom: 1px solid rgb(229 229 229);
    padding-bottom: 10px;
}
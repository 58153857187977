@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .left-nav {
    display: none;
  }
  .main-content {
    width: 100% !important;
    margin: 0 !important;
  }
  .home-section::before {
    left: 0 !important;
    width: 100% !important;
  }
  .menu-toogle-icon {
    display: block !important;
  }

  .main-nav_mobile_toggle__visible {
    display: block !important;
    width: 215px !important;
  }
}

@media(min-width: 1280px) {
  .resume-rating {
    .title {
      text-align: left !important;
    }
    .rating {
      i {
        font-size: 13px;
      }
    }
    span:nth-child(1) {
      display: inline-block !important;
    }
  }
}

@media (max-width: 767px) {
  .about-me__content ul li {
    flex: 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .about-me__content ul li {
    flex: 50% !important;
    max-width: 50% !important;
  }
  
}
